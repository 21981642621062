import React from 'react';
import PropTypes from 'prop-types';

/**
 * @ignore
 * @param {Object} props
 * @param {InputRangeClassNames} props.classNames
 * @param {Function} props.formatLabel
 * @param {string} props.type
 */
let count = 0;

export default function Label(props) {
  const labelValue = props.formatLabel ? props.formatLabel(props.children, props.type) : props.children;
  let label = '';

  if (props.minTextLabel && props.maxTextLabel) {
    label = !count ? props.minTextLabel : props.maxTextLabel;
    count += 1;
    if (count > 1) count = 0;
  }

  return (
    <span className={props.classNames[`${props.type}Label`]}>
      <span className={props.classNames.labelContainer + (label ? ' input-range-label-bump' : '')}>
        <div className="input-range__label-line1">{label}</div>
        <div className="input-range__label-line2">{labelValue}</div>
      </span>
    </span>
  );
}

/**
 * @type {Object}
 * @property {Function} children
 * @property {Function} classNames
 * @property {Function} formatLabel
 * @property {Function} type
 */
Label.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.objectOf(PropTypes.string).isRequired,
  formatLabel: PropTypes.func,
  minTextLabel: PropTypes.string,
  maxTextLabel: PropTypes.string,
  type: PropTypes.string.isRequired,
};
